// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e5e935e3-fe03-4abe-8dd3-f5d83aee3f71",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yaG48a7FH",
    "aws_user_pools_web_client_id": "4ogaar305cet6fboh4279mj68j",
    "aws_appsync_graphqlEndpoint": "https://myfh3gvpivdkxmfj4fcoqgyqji.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "awsexample-gatsbyaws",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
