import React from 'react';


export default function ComingSoon() {
  return (

<div className="wrapper">
    <div className="content">
      <div className="item">
        <h1>COMING SOON</h1>
        <p>This website is under construction.</p>
      </div>
    </div>
  </div>
)
}
